<template>
  <ul v-if="canShow">
    <component
      :is="resolveNavItemComponent(item)"
      v-for="(item, index) in menus"
      :key="index"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  setup() {
    return {
      resolveNavItemComponent,
    }
  },
  data() {
    return {
      defaultRoute: '',
      canShow: false,
    }
  },
  mounted() {
    this.subDashboardRoute()
  },
  methods: {
    subDashboardRoute() {
      this.canShow = false
      let defaultRoute = 'admin.assignor.dashboard'
      const route = this.$router.currentRoute?.name?.split('.')[1]

      if (this.$store.state.auth.userData.type === 'Admin::User') {
        defaultRoute = `admin.${route}.dashboard`
      }

      this.defaultRoute = defaultRoute

      setTimeout(() => {
        this.canShow = true
      }, 10)
    },
    currentPortal(){
      // Pega o portal baseado no path da url
      console.log(this.$route.path)
      const portal = this.$route.path.split('/')[1]
      return {
        admin: 'admin',
        investidor: 'investor',
        cedente: 'assignor',
        fornecedor: 'provider',
        prestadordeservico: 'provider'
      }[portal]
    },
  },
  computed: {
    menus() {
      const menus = {
        'admin': [
          {
            title: 'Dashboard Admin',
            icon: 'GridIcon',
            route: this.defaultRoute,
            action: 'admin-read',
            resource: 'admin-user',
          },
          {
            title: 'Gestão de dados',
            icon: 'CustomDatabaseIcon',
            route: 'admin.data_management',
            action: 'admin-read',
            resource: 'admin-user',
          },
          {
            title: 'Solicitações de Emissão',
            icon: 'FolderIcon',
            route: 'admin.emissions_request',
            action: 'admin-read',
            resource: 'admin-user',
          },
          // {
          //   title: 'Perfil',
          //   icon: 'UserIcon',
          //   route: 'admin.profile',
          //   action: 'admin-read',
          //   resource: 'admin-user',
          // },
        ],
        'investor': [
          {
            title: 'Emissões',
            icon: 'DollarSignIcon',
            route: 'investor.emissions',
            action: 'investor-read',
            resource: 'investor-user',
          },
          // {
          //  title: 'Calculadora',
          //  icon: 'CustomCalc',
          //  route: 'investor.calculator',
          //  action: 'investor-read',
          //  resource: 'investor-user',
          //},
          {
            title: 'Perfil',
            icon: 'UsersIcon',
            route: 'investor.profile',
            action: 'investor-read',
            resource: 'investor-user',
          },
          {
            title: 'Obrigações',
            icon: 'FileTextIcon',
            route: 'investor.events',
            action: 'investor-read',
            resource: 'investor-user',
          },
        ],
        'assignor': [
          {
            title: 'Minhas Emissões',
            icon: 'DollarSignIcon',
            route: 'assignor.emissions',
            action: 'assignor-read',
            resource: 'assignor-user',
          },
          //{
          //  title: 'Calculadora',
          //  icon: 'CustomCalc',
          //  route: 'assignor.calculator',
          //  action: 'assignor-read',
          // resource: 'assignor-user',
          //},
          {
            title: 'Usuários e empresas',
            icon: 'UsersIcon',
            route: 'assignor.users.users',
            action: 'assignor-read',
            resource: 'assignor-user',
          },
          {
            title: 'Obrigações',
            icon: 'FileTextIcon',
            route: 'assignor.events',
            action: 'assignor-read',
            resource: 'assignor-user',
          },
          {
            title: 'Chaves de API',
            icon: 'KeyIcon',
            route: 'assignor.apiKeys',
            action: 'assignor-read',
            resource: 'assignor-user',
          },
          // {
          //   title: 'Notas e recibos',
          //   icon: 'CustomNotasERecibosIcon',
          //   route: 'assignor.notes',
          //   action: 'assignor-read',
          //   resource: 'assignor-user',
          // },
        ],
        'provider': [
          {
            title: 'Minhas Emissões',
            icon: 'DollarSignIcon',
            route: 'provider.emissions',
            action: 'provider-read',
            resource: 'provider-user',
          },
          {
            title: 'Usuários e empresas',
            icon: 'UsersIcon',
            route: 'provider.users.users',
            action: 'provider-read',
            resource: 'provider-user',
          },
        ],
      }
      return menus[this.currentPortal()]
    },
  },
  watch: {
    '$route.path'() {
      this.$nextTick(() => {
        this.subDashboardRoute()
      })
    },
  },
}
</script>
